<template>
  <b-form>
    <b-row>
      <b-col>
        <h1>Mail template</h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <b-button
          :disabled="saveDisabled"
          class="mb-5 save-button"
          @click="save()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab class="mb-5" title="detail">
        <b-form-group label="Greeting">
          <b-form-input v-model="mail.greeting"></b-form-input>
        </b-form-group>
        <b-form-group label="Subject">
          <b-form-input v-model="mail.subject"></b-form-input>
        </b-form-group>
        <b-form-group label="Before text">
          <b-form-checkbox v-model="mail.before_is_html">
            Use HTML
          </b-form-checkbox>
          <quill-editor
            v-model="mail.before_text"
            :options="editorOption"
            v-if="mail.before_is_html"
          />
          <b-form-textarea v-model="mail.before_text" v-else> </b-form-textarea>
        </b-form-group>
        <b-form-group label="Button label">
          <b-form-input v-model="mail.action_text"></b-form-input>
        </b-form-group>
        <b-form-group label="Button URL">
          <b-form-input v-model="mail.action_url"></b-form-input>
        </b-form-group>
        <b-form-group label="After text">
          <b-form-checkbox v-model="mail.after_is_html">
            Use HTML
          </b-form-checkbox>
          <quill-editor
            v-model="mail.after_text"
            :options="editorOption"
            v-if="mail.after_is_html"
          />
          <b-form-textarea v-model="mail.after_text" v-else> </b-form-textarea>
        </b-form-group>
        <b-form-group label="Ending">
          <b-form-input v-model="mail.ending"></b-form-input>
        </b-form-group>
        <!-- <h2>Preview</h2>
      <span v-html="page.html[language]"></span> -->
      </b-tab>
      <b-tab title="Send messages">
        <vuetable ref="sent-table" :data="filteredMails" :fields="fields">
          <template slot="recipients" scope="props">
            <div v-for="(recipient, index) in props.rowData.recipients" :key="index">
              {{recipient}}
            </div>
          </template>
          <template slot="receivedat" scope="props">
            {{props.rowData.receivedat | moment("DD/MM/YYYY, h:mm:ss a")}}
          </template>
        </vuetable>
      </b-tab>
    </b-tabs>
  </b-form>
</template>
<script>
import Vuetable from "vuetable-2";
export default {
  data() {
    return {
      index: "",
      saveDisabled: true,
      saveMessage: "",
      afterIsHtml: false,
      editorOption: {},
      submitted: false,
      fields: [
        {
          name: 'messageid',
          title: 'id'
        },
        {
          name: 'from',
          title: 'from'
        },
        {
          name: 'recipients',
          title: 'recipients'
        },
        {
          name: 'status',
          title: 'status'
        },
        {
          name: 'receivedat',
          title: 'recieved at'
        },
      ]
    };
  },
  components: {
    Vuetable,
  },
  async created() {
    this.isLoading = true;
    if (this.$route.query.id) {
      await this.doFetch();
      await this.fetchSendMails();
    } else {
      this.$store.commit("Mails/EMPTY_CURRENT");
    }
    this.saveDisabled = true;
    this.saveMessage = "";
    this.isLoading = false;
  },
  watch: {
    mail: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  methods: {
    async doFetch() {
      await this.$store.dispatch("Mails/fetchById", this.$route.query.id);
    },
    async fetchSendMails() {
      await this.$store.dispatch("Mails/fetchByTemplate", this.mail?.id);
    },
    async save() {
      this.isLoading = true;
      this.submitted = true;
      this.$validator.validateAll().then(async (isValid) => {
        if (!isValid) {
          return;
        }
        if (this.mail.id) {
          await this.$store.dispatch("Mails/update", this.mail);
        } else {
          await this.$store.dispatch("Mails/create", this.mail);
        }
        this.$root.$bvToast.toast("Page settings saved!", {
          variant: "success",
        });
        this.saveDisabled = true;
        this.saveMessage = "";
        this.submitted = false;
      });
      this.isLoading = false;
    },
  },
  computed: {
    mails() {
      return this.$store.state.Mails.mails;
    },
    mail() {
      return this.$store.state.Mails.mail;
    },
    filteredMails() {
      return this.$store.state.Mails.filteredMails;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
  },
};
</script>
